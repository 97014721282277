import React from 'react'
import Imgage from '../../assets/images/img.png'
import HomeColumnBetter from "../../components/HomeColumnBetter";
import './style.css'
import { Img } from "../../components";
import ADS from './Washta Icons-01.svg'
import CardImg from '../../assets/images/Card.png'
import Squareimg from '../../assets/images/Square.svg'
import ASDf from './Washta-Icons.svg'
const card = () => {
  return (
    <div className='btn-card'>
    <div className="flex gap-[35px] self-stretch md:flex-col w-[91%] m-auto">
    <div className="relative h-[350px] w-full rounded-[26px] border border-solid border-black-900_26 bg-indigo-300 bg-[url(/public/images/img_frame_1171276568.png)] bg-cover bg-no-repeat px-[41px] md:px-5 md:pt-5">
        <div className="absolute  left-0 right-0 m-auto h-[709px]  md:h-auto" style={{top:0}}>
          <img src={Imgage} alt="main"  style={{borderRadius:'27px'}} />
         
        </div>
      </div>
      <div className="flex w-full flex-col gap-[35px]">
        {[...Array(1)].map((d, index) => (
          <HomeColumnBetter
            image={ADS}
            text=" Select Your Location                      "
            text1=" Pick the spot where you’ve parked your car using the interactive map in our app."
            key={"listbetter" + index}
            className="flex flex-1 flex-col items-start justify-center rounded-[26px] border border-solid border-black-900_7f p-[25px] sm:p-5"
          />
        ))}
         <HomeColumnBetter
            image={CardImg}
            text=" Pay Online"
            text1="Seamlessly pay through the app—safe, secure, and quick.  "
            key={"listbetter"}
            className="flex flex-1 flex-col items-start justify-center rounded-[26px] border border-solid border-black-900_7f p-[25px] sm:p-5"
          />
      </div>
     
      <div className="flex w-full flex-col gap-[35px]">
        {[...Array(1)].map((d, index) => (
          <HomeColumnBetter
            image={ASDf}
            text="Enjoy Your Clean Car"
            text1=" Return to your car looking fresh, without having to lift a finger!"
            key={"listautomated" + index}
            className="flex flex-1 flex-col items-start justify-center rounded-[26px] border border-solid border-black-900_7f p-[25px] sm:p-5"
          />
        ))}
         <HomeColumnBetter
            image={Squareimg}
            text=" Run Your Errands"
            text1="While you’re out, our expert team gets to work cleaning your car to perfection."
            key={"listbetter"}
            className="flex flex-1 flex-col items-start justify-center rounded-[26px] border border-solid border-black-900_7f p-[25px] sm:p-5"
          />
      </div>
    </div>
    </div>
  )
}

export default card