
import React, { useEffect, useState } from 'react';
import AdminSidebar from 'admin/sidebar/AdminSidebar';
import { Modal } from 'antd';
import Switch from 'react-switch';
import { CiSearch } from "react-icons/ci";
import { Checkbox, FormControlLabel } from '@mui/material';
import ButtonDahbaord from 'components/ButtonDahbaord/ButtonDahbaord';
import axios from 'axios';
import DefulatImg from '../../assets/images/app.logo.jpeg';
import ServicesFeesApplying from './ServicesFeesApplying';
import '../servicetiming/ServiceTiming.css';
import { ToastContainer, toast } from 'react-toastify';

const ServiceFee = () => {
  const [isSpecificProvider, setIsSpecificProvider] = useState(false);
  const [selectedProviders, setSelectedProviders] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [applyAtAll, setApplyAtAll] = useState(false);
  const [washtaFees, setWashtaFees] = useState('');
  const [isAmountTaxable, setIsAmountTaxable] = useState(false);
  const [applyAs, setApplyAs] = useState('perService');
  const [feeType, setFeeType] = useState('');
  const [data, setData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const allData = selectedProviders?.map((provider) => provider.id);

console.log('selectedProviders',allData)
  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem('accessTokenAdmin');
      const apiUrl = 'https://backend.washta.com/api/admin/shop';

      try {
        const response = await axios.get(apiUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setData(response.data.data.reverse());
      } catch (err) {
        console.error('Error fetching data:', err.message);
      }
    };

    window.scrollTo(0, 0); // Scroll to the top on load
    fetchData();
  }, []);
  const filteredData = data.filter((provider) =>
    provider.shopName.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const handleSwitchChange = () => {
    if (isSpecificProvider) {
      // Switching off: Select all shops by default
      setApplyAtAll(true);
      setSelectedProviders([]); // Clear any manual selections
    } else {
      // Switching on: Enable manual selection
      setApplyAtAll(false);
    }
    setIsSpecificProvider(!isSpecificProvider);
  };

  const handleSelectProvider = (provider) => {
  
    setSelectedProviders((prev) => {
      const isSelected = prev.some((item) => item.id === provider._id); // Check if already selected
      if (isSelected) {
        // Remove the object if it's already selected
        return prev.filter((item) => item.id !== provider._id);
      } else {
        // Add the object if it's not already selected
        return [...prev, { id: provider._id, shopName: provider.shopName }];
      }
    });
  };
  const handleSelectAll = () => {
    if (selectedProviders.length === data.length) {
      setSelectedProviders([]); // Deselect all if all are selected
    } else {
      setSelectedProviders(
        data.map((provider) => ({
          id: provider._id,
          shopName: provider.shopName,
        }))
      ); // Select all with id and shopName
    }
  };
  

  const handleFeeTypeChange = (e) => {
    const selectedType = e.target.value;
    setFeeType(selectedType);
    setWashtaFees(''); // Reset fees value on fee type change
  };
const handleAcceptClick = async() => {
  if (applyAtAll === false && selectedProviders.length === 0) {
    toast.error('Please select providers or apply to all.');
    return;
  }

  if (!washtaFees || !feeType) {
    toast.error('Please fill in all the required fields.');
    return;
  }

  const payload = {
    isAmountTaxable,
    ApplicableStatus: true,
    feeType,
    WashtaFees: washtaFees,
    applyAs,
    applyAt: applyAtAll ? 'all' : allData,
  };
  const token = localStorage.getItem('accessTokenAdmin');
  try {
    const response = await axios.post('https://backend.washta.com/api/admin/serviceFee', payload, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    console.log(payload, 'response', response.data);
    toast.success('Service fee updated successfully!');
    window.location.reload();
  } catch (error) {
    console.error('API Error:', error.response ? error.response.data : error.message);
    toast.error('Failed to update service fee. Please try again.');
  }
};


  return (
    <div className='mainDiv-all-sideBar'>
      <div className='div-sidebar'>
        <AdminSidebar />
      </div>
      <div className='div-Dashbaord'>
        <div className='width-settimg addtionwidth-setting'>
          <div className='receipt-row' style={{ alignItems: 'center' }}>
            <h1 className='h1-all-heading'>Service Fee</h1>
            <div className='receipt-row' style={{ gap: 20 }}>
              <p className='h5-class-top-class' style={{ fontSize: 16, fontWeight: 500 }}>Specific Service Provider</p>
              <Switch
                onChange={handleSwitchChange}
                checked={isSpecificProvider}
                onColor="#747EEF"
                offColor="#bcbcbc"
                handleDiameter={17}
                uncheckedIcon={false}
                checkedIcon={false}
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                height={20}
                width={36}
              />
            </div>
          </div>

          <div className="dashboard">
            <div className="dashboard-customer-details" style={{ width: '100%' }}>
              <div className="receipt-row" style={{ paddingTop: 10 }}>
                <div style={{ width: '75%' }}>
                  <p className='paragprh-customer-com'>
                    Manage Service Fee for all service providers or a specific one
                  </p>
                  <div style={{ marginTop: 15 }}>
                    <span>Specific Service Providers:</span>
                    <span>
                      {applyAtAll ? (
                        <span className="span-seleceted-servicetiming" style={{ marginRight: '10px' }}>
                          All Selected
                        </span>
                      ) : (
                        selectedProviders.map((p, index) => (
                          <span className="span-seleceted-servicetiming" key={index} style={{ marginRight: '10px' }}>
                            {p.shopName}
                          </span>
                        ))
                      )}
                    </span>
                  </div>
                </div>
                <button
                  onClick={() => isSpecificProvider && setShowPopup(true)}
                  className={isSpecificProvider ? 'btnactive' : 'btninactive'}
                  disabled={!isSpecificProvider}
                >
                  Select Service Providers
                </button>
              </div>

              <div className="form-group" style={{ marginTop: 30 }}>
                <label className="h5-heaidng-invouice">Select Fee Type</label>
                <select
                  value={feeType}
                  onChange={handleFeeTypeChange}
                  className="select"
                >
                  <option value="" disabled>Plz Select Fee Type</option>
                  <option value="fixed">Fixed</option>
                  <option value="percentage">Percentage</option>
                </select>
              </div>

              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '40px',position:'relative' }}>
                <div className="form-group" style={{ width: '90%',marginBottom:0}}>
                  <label className="h5-heaidng-invouice">Washta Fees</label>
                  <input
                    type={feeType === 'percentage' ? 'number' : 'text'}
                    placeholder={feeType === 'percentage' ? '00.00' : 'AED'}
                    value={washtaFees}
                    onChange={(e) => setWashtaFees(e.target.value)}
                    style={{ borderBottom: '1px solid',padding:5}}
                  />
                  <span className="label-popup-seller" style={{position: 'absolute',right: '53%', bottom: '1.5em'}}>
                    {feeType === 'percentage' ? '%' : 'AED'}
                  </span>
                </div>

                <div className="form-group" style={{ width: '90%' }}>
                  <label className="h5-heaidng-invouice">Is Amount Taxable?</label>
                  <select
                    value={isAmountTaxable ? 'yes' : 'no'}
                    onChange={(e) => setIsAmountTaxable(e.target.value === 'yes')}
                    className="select"
                  >
                    <option value="" disabled>Plz Select</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                </div>
              </div>

              <div style={{ textAlign: 'end', marginTop: 20 }}>
                <ButtonDahbaord
                  handleclick={handleAcceptClick}
                  textButton="Save"
                  height={32}
                  width={"30%"}
                  background='purple'
                />
              </div>
            </div>
          </div>

          <div className='dashboard-customer-details' style={{ width: '100%' }}>
            <label className="label-popup-seller" style={{ paddingTop: 11, fontSize: '18px', paddingRight: '10px' }}>Get all Service Fee</label>
            <ServicesFeesApplying />
          </div>

          <Modal
            title="Select Service Provider(s)"
            open={showPopup}
            onCancel={() => setShowPopup(false)}
            footer={null}
            centered
          >
            <div className='input-feild-dic-icon' style={{width:'100%'}}>
              <CiSearch className='icons-search-size' />
              <input
                type="text"
                placeholder='Search by service provider'
                className='input-feild-class-job-history'
                value={searchQuery} // Bind the search state
                onChange={(e) => setSearchQuery(e.target.value)} // Update state on input change
              />
            </div>

            <div style={{ height: '22em', overflow: 'scroll' }}>
            <FormControlLabel
  style={{ width: '100%', justifyContent: 'space-between', flexDirection: 'row-reverse' }}
  label="SSelect All"
  control={<Checkbox checked={selectedProviders.length === data.length} onChange={handleSelectAll} />}
/>
              <div className="boxCustomerAll">
                {filteredData?.map((provider) => (
                    <div
    className='tope-customer-com-main'
    key={provider._id}
    style={{ display: 'flex', justifyContent: 'space-between', padding: '11px 9px' ,margin:'10px 0px 15px 0px'}}
  >
    <div className='second-row-customer'>
      <img
        src={provider.coverImage || DefulatImg}
        alt="Provider"
        className="image-pro-companies"
      />
      <div className='top-custorm-width'>
        <h5 className='h5-class-top-class'>{provider.shopName}</h5>
        <p className='paragraph-text-p'>{provider.location?.text}</p>
      </div>
    </div>
    <Checkbox
  checked={selectedProviders.some((item) => item.id === provider._id)} // Correctly match the `id`
  onChange={() => handleSelectProvider(provider)} // Handle selection
/>
  </div>
                ))}
              </div>
            </div>
            <div className='container-footer'>
              <ButtonDahbaord
                handleclick={() => setShowPopup(false)}
                textButton="Close"
                height={30}
                width={"100%"}
                background='purple'
              />
            </div>
          </Modal>
        </div>
      </div>
      <ToastContainer/>
    </div>
  );
};

export default ServiceFee;
