import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Button, Text, Img } from "../../components";
import Footer from "../../components/Footer";
import HomeColumnBetter from "../../components/HomeColumnBetter";
import Banner from './Banner'
import Ellise from '../../assets/images/Ellipse 1.png'
import Imgage from '../../assets/images/img.png'
import Header from "./Header";
import CardImg from '../../assets/images/Card.png'
import Squareimg from '../../assets/images/Square.svg'
import Popup from '../popup/Popup'
import './style.css'
import CustomerCare from './customer-service.png'
import CardBtn from './card'
import MapRoute from './responsibility.png'
import WhatsapIcon from './payment-gateway.png'
import ICon from './fast.png'
import ISD from './Washta-Icons.svg'
import Selectlocation from './Washta Icons-01.svg'
export default function Home1Page() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const boxData = [
    {
      icon: CustomerCare ,
      title: "Accordingly with the points",
      description: "Always at your service",
      details: "Car Wash Anywhere, Anytime: Get your car cleaned wherever it’s parked – from your office lot to your driveway.",
      linkText: "Call us",
      link: "#",
    },
    {
      icon: ICon,
      title: "Seamless & Quick Service",
      description: "Always at your service",
      details: "Request a wash in under a minute through our easy-to-use app",
      linkText: "WhatsApp us",
      link: "#",
    },
    {
      icon: WhatsapIcon,
      title: "Hassle-Free Online Payment",
      description: "iSTYLE is the Authorised Apple Premium Partner in UAE",
      details: "Pay securely via the app",
      linkText: "Find a store nearby",
      link: "#",
    },
    {
      icon: MapRoute,
      title: "Rewards & Benefits",
      description: "iSTYLE is the Authorised Apple Premium Partner in UAE",
      details: "Special offers and more same Call Center replaced with Carwash Anytime Anywhere and so on ",
      linkText: "Find a store nearby",
      link: "#",
    },
  ];
  return (
    <>
      <Helmet>
        <title>Washta APP</title>
        <meta name="description" content="Web site created using create-react-app" />
      </Helmet>

      {/* home main section */}
      <div className="w-full bg-white-A700" style={{position:'relative'}}>
        <Header/>
        <div className="flex flex-col items-end">
          {/* hero image section */}
          <Img src={Ellise} alt="image" object className=" h-[200px]w-[100%] object-contain" style={{position:'absolute',width:'70%'}} />
          <div className="relative  flex flex-col items-center gap-[124px] self-stretch md:gap-[93px] sm:gap-[62px]">
            
            <Banner togglePopup={togglePopup} />
            
            <div className="container-xs flex flex-col items-center gap-[86px] md:gap-16 md:p-5 sm:gap-[43px]">
              {/* features section */}
              <div className="flex w-[94%] flex-col items-center gap-[10px] px-[7px] pt-[7px] md:w-full md:gap-[75px] sm:gap-[50px]">
                <Text size="xl" as="p" className="text-center !font-generalsans !text-black-900">
                How It Works
                </Text>
                <Text size="large" as="p" className="w-[55%] text-center !text-black-900_bf md:w-full" style={{paddingTop:'20px'}}>
                No more wasting time at the carwash. With our innovative mobile app, you can get your car cleaned wherever you are—whether it’s while you’re at work, running errands, or at the grocery store. Simply follow these easy 
                </Text>
              </div>
              <CardBtn/>
              <div className="card-name-btn">


            
              <div className="flex gap-[35px] self-stretch md:flex-col w-[91%] m-auto">
                <div className="flex w-full flex-col gap-[35px]">
                  {[...Array(1)].map((d, index) => (
                    <HomeColumnBetter
                      image={Selectlocation}
                      text=" Select Your Location                      "
                      text1=" Pick the spot where you’ve parked your car using the interactive map in our app.                    "
                      key={"listbetter" + index}
                      className="flex flex-1 flex-col items-start justify-center rounded-[26px] border border-solid border-black-900_7f p-[25px] sm:p-5"
                    />
                  ))}
                   <HomeColumnBetter
                      image={CardImg}
                      text=" Pay Online"
                      text1="Seamlessly pay through the app—safe, secure, and quick. "
                      key={"listbetter"}
                      className="flex flex-1 flex-col items-start justify-center rounded-[26px] border border-solid border-black-900_7f p-[25px] sm:p-5"
                    />
                </div>
                <div className="relative h-[629px] w-full rounded-[26px] border border-solid border-black-900_26 bg-indigo-300 bg-[url(/public/images/img_frame_1171276568.png)] bg-cover bg-no-repeat px-[41px] md:px-5 md:pt-5">
                  <div className="absolute  left-0 right-0 m-auto h-[709px]  md:h-auto">
                    <Img src={Imgage} alt="main" className="h-[630px] w-full object-cover" style={{borderRadius:'27px'}} />
                   
                  </div>
                </div>
                <div className="flex w-full flex-col gap-[35px]">
                  {[...Array(1)].map((d, index) => (
                    <HomeColumnBetter
                    image={Squareimg}
                      text="Run Your Errands"
                      text1="While you’re out, our expert team gets to work cleaning your car to perfection."
                      key={"listautomated" + index}
                      className="flex flex-1 flex-col items-start justify-center rounded-[26px] border border-solid border-black-900_7f p-[25px] sm:p-5"
                    />
                  ))}
                   <HomeColumnBetter
                    image={ISD}
                      
                      text=" Enjoy Your Clean Car"
                      text1="Return to your car looking fresh, without having to lift a finger!"
                      key={"listbetter"}
                      className="flex flex-1 flex-col items-start justify-center rounded-[26px] border border-solid border-black-900_7f p-[25px] sm:p-5"
                    />
                </div>
              </div>
              </div>
              <Button onClick={togglePopup} shape="round" className="min-w-[150px] sm:px-1" style={{fontSize:'15px'}}>
              Notify me
              </Button>
            </div>
           
            <div className="grid grid-cols-4 md:grid-cols-1 lg:grid-cols-3 gap-6 p-6  w-[93%] font-[Manrope]">
            {boxData.map((box, index) => (
        <div
          key={index}
          className="bg-white shadow-lg rounded-lg p-6 text-center border border-gray-200"
        >
          <div className="mb-4 flex justify-center items-center"><img src={box.icon} alt="" className=" w-[17%]" /></div>
          <h3 className="text-2xl font-semibold mb-2">{box.title}</h3>
          {/* <p className="text-gray-600">{box.description}</p> */}
          <p className="text-gray-500 whitespace-pre-line text-sm mt-2">
            {box.details}
          </p>
          {/* <a
            href={box.link}
            className="text-[#747EEF] font-medium mt-4 inline-block hover:underline"
          >
            {box.linkText} &gt;
          </a> */}
        </div>
      ))}
    </div>
    {/* <div className="flex w-[94%] flex-col items-center gap-[10px] px-[7px] pt-[7px] md:w-full md:gap-[75px] sm:gap-[50px]">
                <Text size="xl" as="p" className="text-center !font-generalsans !text-black-900">
                Why Choose Us <span className="text-[#747EEF]">?</span>
                </Text>
                <Text size="large" as="p" className="w-[55%] text-center !text-black-900_bf md:w-full" style={{paddingTop:'20px'}}>
                  Show your interest today, and get notified when our application is launched to register your company
                  the earliest and enjoy the below benefits to grow your business.
                </Text>
              </div> */}
              <div className="flex w-[94%] flex-col items-center gap-[10px] px-[7px] pt-[7px] md:w-full md:gap-[75px] sm:gap-[50px]">
  <Text size="xl" as="p" className="text-center !font-generalsans !text-black-900">
    Why Choose Us <span className="text-[#747EEF]">?</span>
  </Text>
  {/* <Text
    size="large"
    as="p"
    className="w-[55%] text-center !text-black-900_bf md:w-full"
    style={{ paddingTop: "20px" }}
  >
    Show your interest today, and get notified when our application is launched to register your company
    the earliest and enjoy the below benefits to grow your business.
  </Text> */}
  <ul className="list-disc pl-6 text-left text-black-900 space-y-2 text-center list-none ">
    <li>
      <span className="font-semibold text-black-900">Convenience:</span> Forget waiting in line at the carwash or chasing
      the professionals at the parking lot. We come to you.
    </li>
    <li>
      <span className="font-semibold text-black-900">Quality Results:</span> Our professional detailers provide a
      top-tier clean, every time.
    </li>
    <li>
      <span className="font-semibold text-black-900">Time-Saving:</span> Let us handle your car wash while you focus on
      what matters.
    </li>
  </ul>
</div>

    
            {/* faq section */}
            <div className="container-xs px-36 md:p-5 md:px-5">
              <div className="flex items-center justify-center gap-2.5 rounded-[26px] bg-black-900_07 p-[50px] md:flex-col md:p-5">
                <div className="flex flex-1 flex-col items-start gap-1 pt-[5px] md:self-stretch">
                  <Text size="xl" as="p" className="!font-generalsans tracking-[-1.00px] !text-black-900" style={{fontSize:'30px'}}>
                  Get Notified When Our App Launches!
                  </Text>
                  <Text as="p" className="w-[69%] !text-black-900_bf md:w-full" style={{fontSize:'18px'}}>
                    If you don&#39;t find an answer to your question, contact us, and our team will get in touch with
                    you.
                  </Text>
                </div>
                <Button onClick={togglePopup} shape="round" className="min-w-[150px] md:p-5 sm:px-5" style={{fontSize:'15px'}}>
                Notify me
                </Button>
              </div>
            </div>
            {isPopupOpen && (
              <div>
                 <Popup  Cross={togglePopup}/>
                </div>
       
      )}
            {/* footer section */}
            <Footer className="flex items-center justify-center self-stretch bg-indigo-300 py-[46px] md:py-5" />
          </div>
        </div>
      </div>
    </>
  );
}
