
import React, { useEffect, useState } from 'react';
import './Become.css';
import ImgSingin from '../../assets/images/singup.png';
import Overly from '../../assets/images/overly.png';
import InputFeild from 'components/Input/Input';
import { Button } from 'components';
import Header from 'pages/Home1/Header';
import Footer from 'components/Footer';
import { MdOutlineAttachment } from "react-icons/md";
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios'; 

const AddBusniessDetails = () => {
  const navigation = useNavigate();
  const location = useLocation();
  const { item } = location.state || {};
  const { user } = item || {};
  const token = localStorage.getItem('accessToken');

  const [formData, setFormData] = useState({
    companyName: '',
    location: '',
    vatNumber: '',
    uploadDoc: null,
    fullName: '',
    position: '',
    email: user?.email || '',
  });
  console.log('formData',formData)
  const [uploading, setUploading] = useState(false); // To manage uploading state
  const [errors, setErrors] = useState({}); // To manage validation errors

  useEffect(() => {
    // Automatically scroll to the top when the component loads
    window.scrollTo(0, 0);

    // Get current location when the component mounts
    getCurrentLocation();
  }, []);
  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(async (position) => {
        const lat = position.coords.latitude;
        const lng = position.coords.longitude;
  
        try {
          // Google Maps Geocoding API with correct API key
          const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyB_nNvYWSCB2haI7DCgR6chQmsg-T4oj8s`);
          const locationName = response.data.results[0].formatted_address; // Get formatted address
  console.log('locationName',locationName)
          // Set the location field in form data
          setFormData((prevData) => ({
            ...prevData,
            location: locationName || '', // Set location if found, otherwise empty
          }));
        } catch (error) {
          console.error('Error fetching location:', error);
        }
      }, (error) => {
        console.error('Error getting current location:', error);
      });
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  };
  

  // const handleChange = (field, value) => {
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     [field]: value // Dynamically updating the formData state
  //   }));
  // };
  const handleChange = (field, value) => {
    if (field === 'vatNumber') {
      // Restrict to numeric values only
      if (!/^\d*$/.test(value)) return; 
    }
  
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleFileChange = (e) => {
    setFormData({
      ...formData,
      uploadDoc: e.target.files[0], // Update uploadDoc with selected file
    });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.companyName) newErrors.companyName = 'Company Name is required.';
    if (!formData.location) newErrors.location = 'Location is required.';
    if (!formData.vatNumber) newErrors.vatNumber = 'VAT Number is required.';
    if (!formData.uploadDoc) newErrors.uploadDoc = 'Please upload a document.';
    if (!formData.fullName) newErrors.fullName = 'Full Name is required.';
    if (!formData.position) newErrors.position = 'Position is required.';
    if (!formData.email) newErrors.email = 'Email is required.';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {
      setUploading(true);
      const uploadFormData = new FormData();
      uploadFormData.append('file', formData.uploadDoc);

      const uploadResponse = await axios.post('https://backend.washta.com/api/uploadFile', uploadFormData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      });

      const documentUrl = uploadResponse.data.data.path;

      const businessDetails = {
        uploadDocument: documentUrl,
        companyName: formData.companyName,
        location: formData.location,
        VATNumber: formData.vatNumber,
        fullName: formData.fullName,
        position: formData.position,
        email: formData.email
      };
     
      const businessResponse = await axios.post(`https://backend.washta.com/api/seller/Business/${item?.user?.id}`, businessDetails, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      navigation('/not-verified');
      setFormData({
        companyName: '',
        location: '',
        vatNumber: '',
        uploadDoc: null,
        fullName: '',
        position: '',
        email: user?.email || '',
      });
    } catch (error) {
      console.error('Error submitting form:', error);
    } finally {
      setUploading(false);
    }
  };

  return (
    <>
      <Header />
      <div style={{ position: 'relative' }}>
        <div className='row'>
          <img src={Overly} className='overlyClassName' alt="Logo" />
          <div className='addbussiness-detsilsx'>
            <div className='innerDiv-form'>
              <h1 className='h1-heaing'> Add your business details </h1>
              <InputFeild 
                paddingBottom="5px"
                type='text' 
                label="Company Name" 
                placeholder="Company Name" 
                value={formData.companyName} 
                onChange={(value) => handleChange('companyName', value)} 
              />
              {errors.companyName && <p className="error-text">{errors.companyName}</p>}
              
              <div className='gapmargin'> 
                <InputFeild 
                  paddingBottom="5px"
                  type='text' 
                  label="Location" 
                  placeholder="Location" 
                  value={formData.location} 
                  onChange={(value) => handleChange('location', value)} 
                />
                {errors.location && <p className="error-text">{errors.location}</p>}
              </div>
              
              <div className='gapmargin'> 
                <InputFeild 
                  paddingBottom="5px"
                  type="number"
                  label="VAT Number" 
                  placeholder="VAT Number" 
                  value={formData.vatNumber} 
                  onChange={(value) => handleChange('vatNumber', value)} 
                />
                {errors.vatNumber && <p className="error-text">{errors.vatNumber}</p>}
              </div>
              
              <div className='gapmargin' style={{ display: 'flex', position: 'relative', borderBottom: '1px solid white', paddingBottom: 10 }}> 
                <input
                  type="file"
                  id="fileUpload"
                  accept=".pdf"
                  onChange={handleFileChange}
                  style={{ display: 'none' }}
                />
                {!formData.uploadDoc ? (
                  <label htmlFor="fileUpload" style={{ cursor: 'pointer', width: '100%', color: '#d9dbed', paddingBottom: 0, fontSize: '16px' }}>
                    Upload Documents
                  </label>
                ) : (
                  <p className="uploaded-file-name" style={{ color: "white", fontWeight: 100 }}>{formData.uploadDoc.name}</p>
                )}
                <MdOutlineAttachment style={{ position: 'absolute', right: '10px', color: 'white', fontSize: '20px', transform: 'rotate(45deg)' }} />
              </div>
              {errors.uploadDoc && <p className="error-text">{errors.uploadDoc}</p>}
   
              <div className='gapmargin'> 
                <InputFeild 
                  paddingBottom="5px"
                  type='text' 
                  label="Full Name" 
                  placeholder="Full Name" 
                  value={formData.fullName} 
                  onChange={(value) => handleChange('fullName', value)} 
                />
                {errors.fullName && <p className="error-text">{errors.fullName}</p>}
              </div>
              
              <div className='gapmargin'> 
                <InputFeild 
                  paddingBottom="5px"
                  type='text' 
                  label="Position" 
                  placeholder="Position" 
                  value={formData.position} 
                  onChange={(value) => handleChange('position', value)} 
                />
                {errors.position && <p className="error-text">{errors.position}</p>}
              </div>
              
              <div className='gapmargin'> 
                <InputFeild 
                  paddingBottom="5px"
                  type='email' 
                  label="Email" 
                  placeholder="Email" 
                  value={formData.email} 
                  onChange={(value) => handleChange('email', value)} 
                />
                {errors.email && <p className="error-text">{errors.email}</p>}
              </div>

              <div className='gapmargin'>
                             <Button 
                onClick={handleFormSubmit} 
                shape="round" 
                className="min-w-[100%] sm:px-1" 
                style={{ fontSize: '16px', background: 'white', color: 'black', marginTop: '20px', fontFamily: "'General Sans', sans-serif" }} 
                disabled={uploading}
              >
                {uploading ? 'Uploading...' : 'Finish'}
              </Button>              </div>
            </div>
          </div>
          
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AddBusniessDetails;
